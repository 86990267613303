import React from "react";
import ReactTypingEffect from "react-typing-effect";
import Heading from "../../../components/text/Heading";
import "./Medical.css";
import Head from "../../../components/meta/Head";

function Medical() {
  const pics = [
    "https://plus.unsplash.com/premium_photo-1661563835979-0257f71e34d9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1512061942530-e6a4e9a5cf27?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1516788875874-c5912cae7b43?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1596701062351-8c2c14d1fdd0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1574578831673-0570b75bf2ea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1519624133901-3420156d01b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1574919369977-5ecb109b5a74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1589659964144-cd22a01ec20e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1562932054-d476e39c4c45?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1574919630248-d5fad3f17f51?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
  ];
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={["Medical Clinic Design"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={"Medical Clinic Design Dubai | Healthcare Interior Solutions"}
        desc={
          "Enhance your healthcare facility with expert clinic design in Dubai. Crystal Home creates interiors focused on a healthy, welcoming environment."
        }
        link={
          "https://www.crystalhomeinterior.com/our-services/medical-clinic-design-dubai"
        }
      />
      <div className="medical__banner">
        <div className="medical__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="medical">
        <Heading text2={"The Importance of Healing Spaces"} />

        <div className="medical__content">
          <p>
            The interior design of healthcare facilities plays a vital role in
            shaping the psychological experience of patients. When patients
            enter a clinic, the environment they encounter can either alleviate
            or heighten their anxiety. Thoughtfully designed spaces contribute
            to a sense of calm and reassurance, fostering a healing atmosphere
            that is essential for effective medical care.At Falcon Interior, we
            specialize in{" "}
            <span className="bold__span">medical clinic design</span> that
            prioritizes both aesthetics and functionality. Our experienced team
            collaborates closely with medical planners to create comprehensive
            design solutions tailored specifically for healthcare environments.
            We meticulously consider the unique requirements of healthcare
            providers, patients, and caregivers in every project we undertake.
          </p>

          {/* <Heading text={" Comprehensive Design Services"} /> */}

          <div className="main__retail__text heading">
            <h3>
              Comprehensive Design Services{" "}
              <a href="/our-services" style={{ color: "#cd9a61" }}>
                Interior Design Services
              </a>
            </h3>
          </div>

          <p>
            Our clinic interior design services encompass a wide range of
            offerings, ensuring that every aspect of your healthcare facility is
            thoughtfully planned and executed. Our services include:
          </p>

          <div className="list__spacing">
            <li className="bold__list">Concept Design:</li>

            <span>
              We develop innovative design concepts that reflect your clinic's
              mission and values while addressing the needs of patients and
              staff.
            </span>
          </div>

          <div className="list__spacing">
            <li className="bold__list">Custom Fit-Out Design:</li>

            <span>
              Our fit-out services are tailored to create functional and
              efficient spaces that enhance workflow and patient care
            </span>
          </div>

          <div className="list__spacing">
            <li className="bold__list">Custom Furniture Solutions:</li>

            <span>
              We design and manufacture custom furniture that complements your
              clinic’s interior, ensuring comfort and accessibility for all
              users.
            </span>
          </div>

          <div className="list__spacing">
            <li className="bold__list">Wall-to-Wall Design:</li>

            <span>
              Our comprehensive design approach covers every inch of your
              facility, from waiting areas to examination rooms, creating a
              cohesive and inviting atmosphere.
            </span>
          </div>
        </div>

        <div className="residential">
          <div className="residential__content">
            <Heading text={"Creating Inviting Healthcare Environments"} />

            <p>
              Falcon Interior is dedicated to transforming healthcare spaces
              into inviting and effective environments. Our experienced interior
              designers in Dubai understand the complexities of healthcare
              settings and strive to create designs that promote healing and
              comfort. By incorporating elements such as soft lighting, soothing
              colors, and functional layouts, we enhance the overall patient
              experience
            </p>

            <Heading text={"Your Partner in Medical Clinic Design"} />

            <p>
              Trust Falcon Interior to deliver exceptional{" "}
              <span className="bold__span">medical clinic design</span>
              in Dubai that meets the highest standards of quality and care.
              With our deep understanding of healthcare environments and our
              commitment to innovative design, we can help you create a space
              that not only meets regulatory standards but also fosters a
              positive and healing experience for patients and staff alike.
              Contact us today to learn more about our healthcare interior
              design services and how we can help you create a medical clinic
              that stands out in Dubai!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Medical;
