import React, { useEffect, useState } from "react";
import Banner from "../../components/banner/Banner";
import "../single/Single.css";
import { Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import Head from "../../components/meta/Head";

function FlowerInterior() {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width <= 550) {
      setMobile(true);
    }
  }, [mobile]);
  const excerpts = [
    "https://images.unsplash.com/photo-1525310072745-f49212b5ac6d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Zmxvd2VyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1560790671-b76ca4de55ef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Zmxvd2VyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1606041008023-472dfb5e530f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Zmxvd2VyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1457089328109-e5d9bd499191?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fGZsb3dlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1455582916367-25f75bfc6710?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGZsb3dlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1462524500090-89443873e2b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzl8fGZsb3dlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
  ];
  return (
    <>
      <Head
        link={
          "https://www.crystalhomeinterior.com/our-projects/flower-design-interior"
        }
      />
      <Banner
        img={
          "https://images.unsplash.com/photo-1462524500090-89443873e2b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzl8fGZsb3dlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
        }
      />
      <div className="single">
        <div className="excerpts">
          <Swiper
            spaceBetween={4}
            slidesPerView={mobile ? 1 : 2}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            pagination={{ clickable: false }}
            modules={[Autoplay]}
            className="single__swiper"
          >
            {excerpts.map((client, index) => {
              return (
                <div key={index} className="excerpts__content">
                  <SwiperSlide>
                    <img src={client} alt="" />
                  </SwiperSlide>
                </div>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default FlowerInterior;
