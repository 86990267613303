import React from "react";
import { Helmet } from "react-helmet";

function Head({ title, desc, link }) {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={link} />
        <meta name="description" content={desc} />
        <meta name="keywords" content="react, helmet, meta" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
      </Helmet>
    </div>
  );
}

export default Head;
