import React, { useEffect, useState } from "react";
import "./Clients.css";
import { Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Client1,
  Client10,
  Client2,
  Client3,
  Client4,
  Client5,
  Client6,
  Client7,
  Client8,
  Client9,
} from "../../imports/Images";

import ReactTypingEffect from "react-typing-effect";
import Head from "../../components/meta/Head";

const ReactTypingEffectDemo = () => {
  return (
    <>
      <br />

      <ReactTypingEffect
        text={["clients"]}
        cursorRenderer={(cursor) => <h5>{cursor}</h5>}
        displayTextRenderer={(text, i) => {
          return (
            <h1>
              {text.split("").map((char, i) => {
                const key = `${i}`;
                return (
                  <span key={key} style={i % 2 === 0 ? { color: "white" } : {}}>
                    {char}
                  </span>
                );
              })}
            </h1>
          );
        }}
      />
    </>
  );
};

function Clients() {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width <= 550) {
      setMobile(true);
    }
  }, [mobile]);
  const clients = [
    Client1,
    Client2,
    Client3,
    Client4,
    Client5,
    Client6,
    Client7,
    Client8,
    Client9,
    Client10,
  ];
  return (
    <div className="clients__container">
      <Head
        link={"https://www.crystalhomeinterior.com/clients"}
        title={"Crystal Home Clients | Trusted Interior Design Partners Dubai"}
        desc={
          "Discover trusted clients of Crystal Home Interior. Our partners include top brands in Dubai, showcasing excellence in interior design solutions."
        }
      />
      <div className="clients__banner">
        <div className="clients__header">
          <ReactTypingEffectDemo />
        </div>
      </div>

      {/* <Banner img={ClientsBanner} />
      <Heading text={"our clients"} /> */}

      <div className="clients">
        <Swiper
          spaceBetween={4}
          slidesPerView={mobile ? 2 : 4}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          pagination={{ clickable: false }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {clients.map((client, index) => {
            return (
              <div key={index}>
                <SwiperSlide>
                  <img src={client} alt="" />
                </SwiperSlide>
              </div>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default Clients;
