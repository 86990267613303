import axios from "../../axios";
import React, { useState } from "react";
import Button from "../../components/button/Button";
import {
  CallIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  LocationOnIcon,
  MailIcon,
  TwitterIcon,
} from "../../imports/Icons";
import "./Contact.css";
import Head from "../../components/meta/Head";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [desc, setDesc] = useState("");

  const handleSubmit = async (e) => {
    const newPost = {
      fullName: name,
      email: email,
      phoneNo: phone,
      subject: subject,
      message: desc,
    };
    e.preventDefault();

    try {
      const res = await axios.post("/api/v1/contacts", newPost);
      if (res.status === 200) {
        window.alert("message sent successfully");
        window.location.replace("/");
      } else {
        window.alert("message was not successful");
      }
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <div className="contact">
      <Head link={"https://www.crystalhomeinterior.com/contact"} />
      <div className="contact__left__content">
        <div className="contact__heading">
          <h3>Let’s Connect</h3>
          <h1>LEAVE A MESSAGE</h1>
        </div>

        <div className="contact__body">
          <p>
            <input
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </p>

          <p>
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </p>

          <p>
            <input
              type="text"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </p>

          <p>
            <input
              type="text"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </p>

          <p>
            <textarea
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            ></textarea>
          </p>
        </div>
        <div onClick={handleSubmit} className="contact__btn">
          <Button text={"send a message"} color={"var(--gold)"} />
        </div>
      </div>
      <div className="contact__right__content">
        <div className="content__item">
          <p>
            <CallIcon />
          </p>

          <p>
            <a href="tel:+971551574148">+971551574148</a>
          </p>
        </div>

        <div className="content__item">
          <p>
            <MailIcon />
          </p>

          <p>
            <a href="mailto:info.crystalhome512@gmail.com">
              info.crystalhome512@gmail.com
            </a>
          </p>
        </div>

        <div className="content__item">
          <p>
            <LocationOnIcon />
          </p>

          <p>
            Office 620 B, Burj Kalifa Street, WareHouse No : S 28, Ras AI Khor
          </p>
        </div>

        <div className="content__item socials">
          <p>
            <a
              href="https://www.facebook.com/crystal.home.5477"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
          </p>

          <p>
            <a
              href="https://www.linkedin.com/in/crystal-home-technical-services-718542258?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B73uOwchFTfyCXTHdMO5Y9Q%3D%3D"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon />
            </a>
          </p>

          <p>
            <a
              href="https://www.instagram.com/info.crystalhome512/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
          </p>

          <p>
            <a
              href="https://twitter.com/CrystalHomeTech"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
