import React, { useEffect, useState } from "react";
import "./Testimonials.css";

import { Autoplay, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import { StarHalfIcon, StarIcon } from "../../imports/Icons";
import Review from "../../components/review/Review";
import { Google, Logo, Powered } from "../../imports/Images";
import ReactTypingEffect from "react-typing-effect";
import Head from "../../components/meta/Head";

function Testimonials() {
  const ReactTypingEffectDemo = () => {
    return (
      <>
        <br />

        <ReactTypingEffect
          text={["Testimonials"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width <= 550) {
      setMobile(true);
    }
  }, [mobile]);

  return (
    <div className="testimonials">
      <Head
        link={"https://www.crystalhomeinterior.com/testimonials"}
        title={"Crystal Home Testimonials | Client Reviews in Interior Design"}
        desc={
          " Read testimonials from Crystal Home Interior clients in Dubai. See how our design services have transformed spaces and exceeded expectations"
        }
      />
      <div className="testimonials__banner">
        <div className="testimonials__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="home__reviews testimonials">
        <div className="home__reviews__rate">
          <div className="home__reviews__rate__img">
            <img src={Logo} alt="" />
          </div>

          <div className="home__reviews__rate__details">
            <h3> crystal home </h3>
            <p className="rate">
              <p>4.7 </p>
              <p>
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarHalfIcon />
              </p>
            </p>

            <div className="desc">
              <p>Based on 90 reviews</p>
              <img src={Powered} alt="" />
            </div>
            <div className="review__us">
              <a
                href="https://g.co/kgs/ekbwX2"
                target="_blank"
                rel="noreferrer"
              >
                review us on
              </a>

              <img src={Google} alt="" />
            </div>
          </div>
        </div>
        <Swiper
          spaceBetween={3}
          slidesPerView={1}
          centeredSlides={true}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <Review
              name={"Muhammad Hamza"}
              date={"2 days ago"}
              desc={
                "We hired Crystal Home for a complete landscape renovation, and they exceeded our expectations. Our garden has never looked better!”"
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <Review
              name={"kashif sultan"}
              date={"a month ago"}
              desc={
                "Crystal Home has an amazing gift to transform your home fI loved working with Crystal home and there team and would highly recommend anyone looking for an interior designer who brings creativity but designs something that fits YOU and your style"
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <Review
              name={"harrison"}
              date={"3 months ago"}
              desc={
                "Crystal home team is extremely knowledgeable and professional. Made me feel super comfortable even though it was my first experience working with a designer. Highly recommend."
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <Review
              name={"john doe"}
              date={"3 months ago"}
              desc={
                "Crystal Home has a great sense of what designs are both in style, but will stand the test of time (so you aren't left with a home 10 years down the road with a bathroom which was contemporary 10 years ago, but is now out of style and is going to be a hastle to sell). Knowledge of lighting and traditional materials makes a space feel so open and vibrant. Our bathroom could never had turned out the way it did without her team and inspiration. We can't thank her enough."
              }
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Testimonials;
