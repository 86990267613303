import React from "react";
import ReactTypingEffect from "react-typing-effect";
import Heading from "../../../components/text/Heading";
import "./Hospitality.css";
import Head from "../../../components/meta/Head";

function Hospitality() {
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={["Best Hospitality Design Firms in Dubai"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={"Best Hospitality Design Firms Dubai | Crystal Home Interior"}
        desc={
          "Explore Crystal Home, a top hospitality design firm in Dubai, creating luxurious interiors for hotels and restaurants. Contact us today for booking!"
        }
        link={
          "https://www.crystalhomeinterior.com/our-services/best-hospitality-design-firms-dubai"
        }
      />
      <div className="hospitality__banner">
        <div className="hospitality__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="hospitality">
        <Heading text2={"Exceptional Hospitality Designs Tailored for You"} />

        <div className="hospitality__content">
          <p>
            Creating a luxurious and inviting space is essential for enhancing
            guest experiences in the hospitality industry. At
            <a href="/"> Crystal Home,</a> we understand that the ideal
            hospitality interior design strikes a perfect balance between
            high-end luxury and practical functionality. Whether you’re dreaming
            of a chic hotel, a cozy resort, or a vibrant restaurant, we offer
            comprehensive solutions tailored to your unique vision. Our team of{" "}
            <span style={{ fontWeight: 800 }}>
              {" "}
              hospitality design consultants
            </span>{" "}
            is dedicated to delivering designs that resonate with your brand and
            meet the expectations of your clientele. With years of expertise in
            transforming spaces, we pride ourselves on being recognized among
            the{" "}
            <span style={{ fontWeight: 800 }}>
              best hospitality design firms{" "}
            </span>{" "}
            in Dubai. Our experienced designers and project management
            specialists work diligently to ensure that your ideas come to life,
            enhancing the overall appeal of your establishment. At{" "}
            <a href="/">Crystal Home</a>, we prioritize your vision. We believe
            that collaboration is key to achieving the extraordinary, which is
            why we actively involve you in the design process. Our commitment to
            creativity and innovation allows us to craft one-of-a-kind interiors
            that leave a lasting impression on your guests.
          </p>

          <Heading text={" Our Specialties Include"} />

          <ul>
            <li>
              <span style={{ marginBottom: 5, fontWeight: 800 }}>
                Hotels & Resort:{" "}
              </span>
              Luxurious designs that combine comfort and style.
            </li>

            <li>
              <span style={{ marginBottom: 5, fontWeight: 800 }}>
                Restaurants:{" "}
              </span>
              Unique atmospheres that elevate dining experiences.{" "}
            </li>

            <li>
              <span style={{ marginBottom: 5, fontWeight: 800 }}>
                Retail Stores:{" "}
              </span>
              Engaging layouts that attract and retain customers.{" "}
            </li>
            <li>
              <span style={{ marginBottom: 5, fontWeight: 800 }}>Shops: </span>
              Tailored designs for boutique spaces.{" "}
            </li>
            <li>
              <span style={{ marginBottom: 5, fontWeight: 800 }}>
                Salons & Spas:{" "}
              </span>
              Tranquil environments that promote relaxation{" "}
            </li>
            <li>
              <span style={{ marginBottom: 5, fontWeight: 800 }}>
                Project Management:{" "}
              </span>
              Seamless execution from concept to completion.{" "}
            </li>
          </ul>
        </div>

        <div className="hospitality__content">
          <p>
            With a strong reputation in the{" "}
            <span style={{ fontWeight: 800 }}>
              <a href="/">Dubai interior design market</a>
            </span>
            , Crystal Home is committed to solving your interior challenges with
            innovative solutions. Our team is driven by our core values,
            ensuring that every project is handled with integrity and
            excellence. Let us transform your hospitality space into a stunning
            showcase that reflects your brand’s identity and delights your
            visitors. Discover why we're among the{" "}
            <span style={{ fontWeight: 800 }}>
              best hospitality design firms
            </span>{" "}
            in Dubai. Connect with us today to discuss your project and let our
            <span style={{ fontWeight: 800 }}>
              {" "}
              hospitality design consultants{" "}
            </span>
            guide you in creating an unforgettable experience for your guests.
          </p>
        </div>
      </div>
    </>
  );
}

export default Hospitality;
