import React, { useEffect, useState } from "react";
import Banner from "../../components/banner/Banner";
import "../single/Single.css";
import { Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import Head from "../../components/meta/Head";

function BathroomInterior() {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width <= 550) {
      setMobile(true);
    }
  }, [mobile]);
  const excerpts = [
    "https://images.unsplash.com/photo-1604709177225-055f99402ea3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8YmF0aHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1584622650111-993a426fbf0a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YmF0aHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1604014237256-11d475e2a2d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8YmF0aHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1600566752355-35792bedcfea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8YmF0aHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1552321554-5fefe8c9ef14?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8YmF0aHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1620626011761-996317b8d101?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8YmF0aHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1564540583246-934409427776?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8YmF0aHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1583847268964-b28dc8f51f92?ihttps://images.unsplash.com/photo-1586798271654-0471bb1b0517?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGJhdGhyb29tfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1603825491103-bd638b1873b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGJhdGhyb29tfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1616537937163-387d3f079de8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGJhdGhyb29tfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
  ];
  return (
    <>
      <Head
        link={
          "https://www.crystalhomeinterior.com/our-projects/bathroom-interior"
        }
      />
      <Banner
        img={
          "https://images.unsplash.com/photo-1616537937163-387d3f079de8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGJhdGhyb29tfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
        }
      />
      <div className="single">
        <div className="excerpts">
          <Swiper
            spaceBetween={4}
            slidesPerView={mobile ? 1 : 2}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            pagination={{ clickable: false }}
            modules={[Autoplay]}
            className="single__swiper"
          >
            {excerpts.map((client, index) => {
              return (
                <div key={index} className="excerpts__content">
                  <SwiperSlide>
                    <img src={client} alt="" />
                  </SwiperSlide>
                </div>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default BathroomInterior;
