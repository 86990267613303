import React from "react";
import ReactTypingEffect from "react-typing-effect";
import Heading from "../../../components/text/Heading";
import "./Beauty.css";
import Head from "../../../components/meta/Head";

function Beauty() {
  const pics = [
    "https://plus.unsplash.com/premium_photo-1661563835979-0257f71e34d9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1512061942530-e6a4e9a5cf27?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1516788875874-c5912cae7b43?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1596701062351-8c2c14d1fdd0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1574578831673-0570b75bf2ea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1519624133901-3420156d01b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1574919369977-5ecb109b5a74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1589659964144-cd22a01ec20e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1562932054-d476e39c4c45?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1574919630248-d5fad3f17f51?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
  ];

  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={["Salon Interior Design"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={"Salon Interior Design in Dubai | Beauty Salon & Spa Fit-Out"}
        desc={
          "Transform your beauty salon with expert salon interior design services. Specializing in modern and luxury designs that enhance customer experience"
        }
        link={
          "https://www.crystalhomeinterior.com/our-services/salon-interior-design"
        }
      />
      <div className="beauty__banner">
        <div className="beauty__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="beauty">
        <Heading text2={"Beauty Salon & Spa"} />

        <div className="beauty__content">
          <p>
            A captivating salon is essential for attracting clients seeking
            beauty and relaxation. The interior of your salon should not only be
            aesthetically pleasing but also create a soothing ambiance that
            welcomes every visitor. Thoughtful{" "}
            <span className="bold__span">salon interior design</span>—from the
            reception area to the flooring and furniture—plays a critical role
            in turning first-time clients into loyal customers. At Falcon
            Interior, we specialize in premium beauty salon and spa designs that
            elevate the client experience while enhancing your brand identity.
            Our team is committed to delivering exceptional design executions
            tailored to meet the unique mood and concept requirements of your
            salon.
          </p>

          <h3>Creating an Inviting Atmosphere</h3>

          <p>
            A well-designed salon is a sanctuary where clients can unwind and
            indulge in self-care. The atmosphere you create is vital; it should
            promote relaxation and comfort. Our interior designers meticulously
            plan every aspect of your salon's layout to cultivate a calming
            environment that encourages patrons to return.
          </p>

          <h3>
            <a href="/our-services">Comprehensive Services</a>
          </h3>

          <p>
            Falcon Interior has an impressive portfolio of successful projects
            across Dubai and the Middle East, serving both global and local
            clients. Our commitment to client satisfaction drives us to strive
            for excellence in every project. Our{" "}
            <span className="bold__span">beauty salon design</span> services
            include:
          </p>

          <ul>
            <div className="list__spacing">
              <li className="bold__list">
                {" "}
                Interiors of Modern and Vintage Salons:
              </li>

              <span>
                We blend classic charm with contemporary trends to create spaces
                that resonate with your clientele.
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list">
                {" "}
                Interiors of Modern and Traditional Spas:
              </li>

              <span>
                Our designs promote tranquility and rejuvenation, making them
                perfect for any spa setting.
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list">
                {" "}
                Interior Design for Luxury Salons and Spas:{" "}
              </li>

              <span>
                We focus on high-end finishes and luxurious details that elevate
                the experience for discerning clients
              </span>
            </div>
            <div className="list__spacing">
              <li className="bold__list">
                {" "}
                Interiors of Beauty Salons and Parlors:{" "}
              </li>

              <span>
                Our versatile designs cater to all types of beauty
                establishments.
              </span>
            </div>
          </ul>

          <Heading text={"Tailored Design Solutions"} />
          <p>
            The success of any salon hinges on its ability to create a pleasant
            atmosphere. Our{" "}
            <span className="bold__span">salon interior design</span> solutions
            offer a wide range of styles, ensuring that your space aligns with
            your brand identity while providing an inviting environment. We
            specialize in various design themes, including:
          </p>
          <ul>
            <div className="list__spacing">
              <li className="bold__list"> Modern or Contemporary Designs:</li>

              <span>
                Sleek lines and innovative layouts create a fresh and stylish
                look.
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list"> Minimalist Design:</li>

              <span>
                Emphasis on simplicity and functionality ensures an uncluttered
                space that promotes relaxation.
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list"> Arabian Designs:</li>

              <span>
                Rich textures and intricate details provide a warm and welcoming
                ambiance
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list"> Moroccan Designs:</li>

              <span>
                Bold colors and patterns create an exotic feel that captivates
                guests
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list"> Eco-Friendly Designs:</li>

              <span>
                Sustainable materials and practices reflect a commitment to the
                environment, appealing to conscious consumers.
              </span>
            </div>
          </ul>

          <p>
            At Falcon Interior, we leverage cutting-edge technologies in the
            design process, ensuring that our salon interiors are not only
            visually stunning but also functional and efficient. Our unique
            approach to <span className="bold__span">salon design</span>{" "}
            guarantees a fashionable and highly advantageous solution for your
            beauty business. Let us partner with you to create a salon that
            embodies your vision and provides an unforgettable experience for
            your clients. Discover the possibilities with our expert salon
            interior design services today!
          </p>
        </div>

        <div className="residential">
          <div className="residential__content">
            {/* <Heading text={"Luxury Residential Interior Service"} /> */}

            {/* <p>
              Your home is your area to unwind and be yourself. home décor are
              exceptional ways to enhance your environment and make your private
              home a extra relaxing, romantic and intimate residing area.
              growing flowers interior as well as exterior adds beauty, as well
              as keeps the air fresh and aromatic. A swing within the veranda in
              which you could experience the quality night breeze, or a bit spot
              with the aid of the window where you may enjoy a ebook and a
              cuppa, it’s all about creating a area you’re happy to be in.
            </p>

            <p>
              While you select a coloration scheme that is substantially praised
              in correct housework or buy a Victorian stimulated dresser, simply
              due to the fact it's far “elegant”, you reach growing a domestic
              that just appears correct, however lacks character. your home
              should talk volumes about its proprietor, whether within the
              colorations, the furnishings or decor permit your hobbies,
              capabilities and pastimes grace your partitions. let your home be
              your very own non-public artwork gallery.
            </p> */}

            <div className="residential__img">
              <img
                src="https://images.unsplash.com/photo-1628744876497-eb30460be9f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
                alt="salon interior design"
              />

              <img
                src="https://images.unsplash.com/photo-1633110187937-6e3b2f36dfca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
                alt="salon design"
              />
            </div>

            {/* <Heading text={"Interiors of a Luxurious Villa"} /> */}

            {/* <p>
              A safe villa interior design option that never goes wrong in a
              large villa living room is the uniformity of design. Choose a
              familiar neutral colour palette for your living room walls,
              floors, sofa, and furniture.
            </p> */}

            {/* <p>
              Pastel, beige, grey, taupe, cream, and white are among the most
              popular options. Contrast them with accessories of deeper, darker
              hues. If the room seems too one-tone, you can break the monotony
              with a signature chandelier.
            </p>

            <p>
              The most significant upside of a villa interior design is that you
              can add ample opulent décor elements without overwhelming the
              room. The large space and the high ceiling make this possible. So
              use them to your advantage for your luxury villa interiors.
            </p>

            <p>
              The only trick is to choose elements that go well together. For
              instance, the ornate chandelier, textured walls, luxury leather
              sofas, silver accent chairs, glass centre table, and gloss finish
              marble flooring go very well together, giving the room a subdued
              grandiosity.
            </p> */}

            <div className="residential__img">
              <img
                src="https://plus.unsplash.com/premium_photo-1670359037486-41aa5e6594ad?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzF8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
                alt="hair salon design ideas"
              />

              <img
                src="https://images.unsplash.com/photo-1604014237800-1c9102c219da?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzB8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
                alt="hair salon design ideas"
              />
            </div>

            {/* <ol>
              <li>
                <h3>A Kitchen</h3>
              </li>

              <p>
                Kitchens are arguably the most important room when focusing on
                villa interior design. It is the heart of your home, where you
                prepare daily meals,
              </p>

              <p>
                and a hub for family gatherings and making memories. Hence, a
                modern villa’s interior design calls for a spacious,
                clutter-free, functional kitchen with many storage options.
              </p>

              <p>
                The contemporary kitchen design best uses the rectangular space
                with an L- shaped cabinet with lots of storage and a long island
                that can be used for multiple purposes. The large windows make
                the kitchen light and airier, while the stunning light fixture
                puts a spotlight on the island.
              </p>

              <div className="residential__img">
                <img
                  src="https://images.unsplash.com/photo-1556911220-bff31c812dba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8a2l0Y2hlbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
                  alt=""
                />

                <img
                  src="https://images.unsplash.com/photo-1600489000022-c2086d79f9d4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
                  alt=""
                />
              </div>

              <li>
                <h3>Bed room</h3>
              </li>

              <p>
                Bedroom is a place of comfort and relaxation where everyone
                wants the best. As a personal oasis to rejuvenate people want it
                to be their ultimate heaven on earth with the right furniture,
                furnishings, lighting and all the necessary luxuries that they
                can afford. While trends with regard to bedroom colors, layout,
                fabrics, patterns and other details keep changing most people
                like to stick to ideas that meets their comfort level and sense
                of aesthetics. Here are a few bedroom decor trends that have
                remained timeless through the years that can be adopted by
                people of all tastes.
              </p>

              <ul>
                <li>Neutral colors and soft lights</li>

                <li>In with nature</li>

                <li>Classic bedroom</li>

                <li>Minimalist Trend</li>
              </ul>

              <div className="residential__img">
                <img
                  src="https://images.unsplash.com/photo-1574643156929-51fa098b0394?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8YmVkcm9vbXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
                  alt=""
                />

                <img
                  src="https://images.unsplash.com/photo-1540518614846-7eded433c457?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YmVkcm9vbXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
                  alt=""
                />
              </div>

              <li>
                <h3>Bathroom</h3>
              </li>

              <p>
                Crystal home brings you bathroom interior designs that are
                functional and also stylish. Whether you are looking for small
                bathroom ideas or spacious ones, we have created some elegant
                bathroom designs for you to consider for your next home
                renovation project.
              </p>

              <div className="residential__img">
                <img
                  src="https://images.unsplash.com/photo-1584622650111-993a426fbf0a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YmF0aHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                  alt=""
                />

                <img
                  src="https://plus.unsplash.com/premium_photo-1664457233888-523931beef03?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YmF0aHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                  alt=""
                />
              </div>

              <li>
                <h3>Family sitting room</h3>
              </li>

              <p>
                When you live with family, everyone is busy in their corner with
                their work. One way to bring everyone together is to add a
                family entertainment room to your villa interior design where
                you can have a fun time with your loved ones and make memories
                to last a lifetime.
              </p>

              <p>
                A large villa enables the creation of such a spacious,
                sound-proof room. You can even have the space double as a
                mini-conference room for official work to get maximum utility.
              </p>

              <div className="residential__img">
                <img
                  src="https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2l0dGluZyUyMHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                  alt=""
                />

                <img
                  src="https://images.unsplash.com/photo-1613685703305-8592a8a6bfee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8c2l0dGluZyUyMHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                  alt=""
                />
              </div>

              <li>
                <h3>Majlis interior</h3>
              </li>

              <p>
                Review our contemporary majlis interior design range and choose
                the one that reflects your taste and matches your hosting needs.
              </p>

              <div className="residential__img">
                <img
                  src="https://images.unsplash.com/photo-1666673746950-37aa01572928?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8bWFqbGlzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
                  alt=""
                />

                <img
                  src="https://images.unsplash.com/photo-1666673746658-ba1212fbed2f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bWFqbGlzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
                  alt=""
                />
              </div>

              <li>
                <h3>FOR HOSPATILITY INTERIOR:</h3>
              </li>

              <p>
                Every person wants a highly-priced and comfy space to experience
                their excursion and vacations. Hospitality indoors design Dubai
                should have a balance among high-give up luxury and
                functionality. whatever the idea or idea that you have in your
                hotel or inn, we've got a one-prevent answer for you. We assist
                you layout the finest designs based totally on the needs and
                choices of your customers. we can assure that our know-how may
                be superb to that of other agencies in Dubai.
              </p>

              <p>
                we have the exceptional interior designers specialized in
                hospitality indoors design and that is why we are most of the
                top Hospitality interior design groups in Dubai. With years of
                experience and proficient designers and infrastructure control
                specialists, we come up with the great workers inside the
                subject. We value your vision and ideas and add our creativity
                to them.
              </p>

              <p>
                We believe in working closely with our clients to get exactly
                the designs they want. Our staff is dedicated to providing you
                with one-of-a-kind designs that will create a lasting impact. We
                specialize in the following hospitality projects:
              </p>

              <ul>
                <li>Hotels & Resorts</li>

                <li>Restaurants</li>

                <li>Retail Stores</li>
              </ul>

              <div className="commercial__gallery">
                {pics.map((pic, index) => {
                  return (
                    <div className="pic__gallery" key={index}>
                      <div className="pic">
                        <img src={pic} alt="" />
                      </div>
                    </div>
                  );
                })}
              </div>
            </ol> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Beauty;
