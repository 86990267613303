import React from "react";
import ReactTypingEffect from "react-typing-effect";
import Heading from "../../../components/text/Heading";
import "./Landscape.css";
import Head from "../../../components/meta/Head";

function Landscape() {
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={["Landscape Design Dubai"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={"Landscape Design Dubai | Landscaping & Renovation Services"}
        desc={
          "Looking for top landscape design in Dubai? Crystal Home offers expert services, including pool, garden, and more to enhance your space."
        }
        link={
          "https://www.crystalhomeinterior.com/our-services/landscape-design-dubai"
        }
      />
      <div className="landscape__banner">
        <div className="landscape__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="landscape">
        <div className="landscape__content">
          <p style={{ marginTop: 10 }}>
            <span style={{ fontWeight: 800, fontSize: 24 }}>
              Introduction:{" "}
            </span>
            Transform your outdoor spaces into stunning landscapes with{" "}
            <a href="/">Crystal Home,</a> one of the leading landscape design
            companies in Dubai. Whether you’re looking to renovate your pool,
            design a garden, or revamp your interior spaces, our expert team is
            here to bring your vision to life.
          </p>

          <Heading
            text2={"Why Choose Crystal Home for Landscape Design in Dubai?"}
          />

          <p>
            At<a href="/"> Crystal Home,</a> we understand the unique aesthetic
            and functional needs of Dubai's residents. Our experience in both
            interior and exterior design allows us to create cohesive and
            beautiful environments that reflect your style.
          </p>

          <h3>Key Features</h3>

          <ul>
            <div className="list__spacing">
              <li className="bold__list"> Custom Designs:</li>

              <span>
                Tailored landscape solutions that fit your specific needs and
                preferences.
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list"> Quality Materials:</li>

              <span>
                We use only the best materials suited for Dubai’s climate.
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list"> Sustainable Practices:</li>

              <span>
                Environmentally friendly designs that enhance the natural beauty
                of your space{" "}
              </span>
            </div>
          </ul>

          <Heading text={"Our Landscape Design Services"} />

          <div className="landscape__imgs">
            <h3>Pools</h3>
            <p style={{ textAlign: "left" }}>
              Revitalize your pool area with our comprehensive design and
              renovation services. Whether it's adding modern features or a
              complete overhaul, we ensure your pool area becomes a true oasis.
            </p>
            <img
              src="https://images.unsplash.com/photo-1621437102383-99e5cf9859c7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cG9vbHN8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
              alt="pool interior solutions"
            />
          </div>

          {/* <Heading text={"gardens"} /> */}

          <div className="landscape__imgs">
            <h3>Gardens</h3>

            <p style={{ textAlign: "left" }}>
              Revitalize your pool area with our comprehensive design and
              renovation services. Whether it's adding modern features or a
              complete overhaul, we ensure your pool area becomes a true oasis.
            </p>
            <img
              src="https://images.unsplash.com/photo-1585320806297-9794b3e4eeae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Z2FyZGVuc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
              alt="garden interior dubai"
            />
          </div>

          {/* <Heading text={"tree"} /> */}

          <div className="landscape__imgs">
            <h3>Trees & Plants</h3>

            <p style={{ textAlign: "left" }}>
              Choosing the right trees and plants is crucial for creating a
              sustainable and attractive landscape. We select flora that thrives
              in Dubai’s climate, ensuring your garden remains vibrant
              year-round.
            </p>
            <img
              src="https://images.unsplash.com/reserve/bOvf94dPRxWu0u3QsPjF_tree.jpg?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dHJlZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
              alt="Trees & planting solutions "
            />
          </div>

          {/* <Heading text={"plants"} /> */}

          <div className="landscape__imgs">
            <h3>Flowers</h3>

            <p style={{ textAlign: "left" }}>
              Add a splash of color to your landscape with our expert flower
              selection and arrangement services. We design floral layouts that
              enhance the aesthetic appeal of your outdoor space.
            </p>
            <img
              src="https://images.unsplash.com/photo-1519378058457-4c29a0a2efac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Zmxvd2Vyc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
              alt="Flowers interior solutions"
            />
          </div>

          <Heading text2={"Why Dubai Residents Trust Crystal Home"} />

          <p>
            Crystal Home has built a reputation as a top choice for landscape
            design in Dubai due to our commitment to quality, creativity, and
            customer satisfaction. Our projects are not just about aesthetics;
            they are about creating spaces that enhance your lifestyle.
          </p>

          {/* <Heading text={"flowers"} /> */}

          {/* <div className="landscape__imgs">
            <img
              src="https://images.unsplash.com/photo-1519378058457-4c29a0a2efac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Zmxvd2Vyc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
              alt=""
            />
          </div> */}

          <Heading text2={"Case Studies"} />

          <div className="list__spacing">
            <li className="bold__list"> Modern Pool Renovation:</li>

            <span>
              See how we transformed a dated pool area into a contemporary
              retreat.
            </span>
          </div>

          <div className="list__spacing">
            <li className="bold__list">Urban Garden Design:</li>

            <span>
              Discover how we brought nature into a high-rise setting.
            </span>
          </div>
        </div>

        <Heading text2={"Contact Us for Landscape Design in Dubai"} />
        <p>
          Ready to transform your outdoor space? Contact Crystal Home today to
          discuss your landscape design needs. We offer personalized
          consultations to ensure your vision becomes a reality.
        </p>
      </div>
    </>
  );
}

export default Landscape;
