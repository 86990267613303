import React from "react";
import ReactTypingEffect from "react-typing-effect";
import Heading from "../../../components/text/Heading";
import "./Residential.css";
import Head from "../../../components/meta/Head";

function Residential() {
  const pics = [
    {
      url: "https://plus.unsplash.com/premium_photo-1661563835979-0257f71e34d9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
      alt: "high end residential interior design",
    },

    {
      url: "https://images.unsplash.com/photo-1512061942530-e6a4e9a5cf27?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
      alt: "interior decoration service companies in duba",
    },

    {
      url: "https://images.unsplash.com/photo-1516788875874-c5912cae7b43?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
      alt: "décor service companies in dubai",
    },

    {
      url: "https://images.unsplash.com/photo-1596701062351-8c2c14d1fdd0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
      alt: "interior design offices near me",
    },

    {
      url: "https://images.unsplash.com/photo-1574578831673-0570b75bf2ea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      alt: "top interior designers in dubai",
    },

    {
      url: "https://images.unsplash.com/photo-1519624133901-3420156d01b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      alt: "office interior design companies in dubai",
    },

    {
      url: "https://images.unsplash.com/photo-1574919369977-5ecb109b5a74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      alt: "interior design firms near me in dubai",
    },

    {
      url: "https://images.unsplash.com/photo-1589659964144-cd22a01ec20e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      alt: "interior designers near me",
    },

    {
      url: "https://images.unsplash.com/photo-1562932054-d476e39c4c45?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      alt: "top 10 interior design companies in dubai",
    },

    {
      url: "https://images.unsplash.com/photo-1574919630248-d5fad3f17f51?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      alt: "top 10 interior design companies in dubai",
    },
  ];
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={[" Residential Interior Design Dubai "]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={"Residential Interior Designers Dubai | Crystal Home"}
        desc={
          "Transform your space with Crystal Home, top residential interior designers in Dubai. Specializing in high-end services. Call Now +971551574148."
        }
        link={
          "https://www.crystalhomeinterior.com/our-services/residential-interior-designers-dubai"
        }
      />
      <div className="residential__banner">
        <div className="residential__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="residential">
        <div className="residential__content">
          <Heading text2={"Why Choose Crystal Home?"} />

          <p>
            At<a href="/"> Crystal Home,</a> we stand out as leading{" "}
            <span style={{ fontWeight: 800 }}>
              residential interior designers in Dubai
            </span>{" "}
            by combining innovative design with unparalleled craftsmanship. Our
            expertise in{" "}
            <span style={{ fontWeight: 800 }}>
              high-end residential interior design{" "}
            </span>{" "}
            ensures that every project is a blend of luxury, comfort, and style.
            Whether you’re looking to renovate your home, villa, or any
            residential area, our team is dedicated to delivering results that
            exceed expectations.
          </p>

          <div className="residential__img">
            <img
              src="https://images.unsplash.com/photo-1628744876497-eb30460be9f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
              alt="Top-rated interior designers in Dubai"
            />

            <img
              src="https://images.unsplash.com/photo-1633110187937-6e3b2f36dfca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
              alt="Leading interior design firms in Dubai"
            />
          </div>

          <Heading text={"Our High-End Residential Interior Design Services"} />

          <p>
            Designing a large villa living room requires a balance between
            opulence and harmony. A timeless and safe approach is the uniformity
            of design, which ensures that every element works together to create
            a cohesive, luxurious space. For those seeking elegance without
            risk, choosing a familiar neutral color palette is ideal. Shades
            such as pastel, beige, grey, taupe, cream, and white form the
            foundation of your living room’s aesthetic, providing a serene
            backdrop that exudes sophistication.
          </p>

          <p>
            To elevate the room's elegance, introduce contrast with accessories
            in deeper, darker hues. This could include rich-colored throw
            pillows, dark wooden furniture, or metallic accents that add depth
            and interest to the space. A signature chandelier can serve as the
            centerpiece, breaking the monotony and adding a touch of grandeur.
          </p>

          <p>
            The beauty of a high-end residential interior design lies in the
            freedom to incorporate opulent décor elements without overwhelming
            the room. Large spaces with high ceilings, typical of villas in
            Dubai, allow for such luxury. Utilize these features to your
            advantage, whether it’s through ornate chandeliers, textured walls,
            or luxury leather sofas. The key is to select elements that
            harmonize with each other. For instance, the combination of an
            ornate chandelier, textured walls, luxury leather sofas, silver
            accent chairs, a glass center table, and gloss finish marble
            flooring creates a room that radiates subdued grandiosity.
          </p>

          <div className="residential__img">
            <img
              src="https://plus.unsplash.com/premium_photo-1670359037486-41aa5e6594ad?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzF8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
              alt="Premium interior design services Dubai"
            />

            <img
              src="https://images.unsplash.com/photo-1604014237800-1c9102c219da?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzB8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
              alt="Renowned interior designers in Dubai"
            />
          </div>

          <ol>
            <li>
              <h3>A Kitchen</h3>
            </li>

            <p>
              Our kitchen designs are the epitome of modern luxury, merging
              aesthetic appeal with functionality. We offer a variety of
              high-end finishes, from sleek countertops to custom cabinetry,
              ensuring that your kitchen is not just a place for cooking but a
              space where style and functionality coexist. We pay attention to
              details like lighting, storage solutions, and the seamless
              integration of appliances to create a kitchen that is both
              beautiful and practical.
            </p>

            <div className="residential__img">
              <img
                src="https://images.unsplash.com/photo-1556911220-bff31c812dba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8a2l0Y2hlbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
                alt="Elite interior designers Dubai"
              />

              <img
                src="https://images.unsplash.com/photo-1600489000022-c2086d79f9d4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
                alt="High-end interior design studios in Dubai"
              />
            </div>

            <li>
              <h3>Bed room</h3>
            </li>

            <p>
              Your bedroom is your personal sanctuary, and our designs reflect
              that. We focus on creating a tranquil environment where you can
              relax and rejuvenate. From the choice of soothing color palettes
              to the selection of plush bedding and furniture, every aspect is
              designed with your comfort in mind. We also incorporate smart
              storage solutions and lighting that enhances the ambiance,
              ensuring that your bedroom is a perfect blend of luxury and
              practicality.
            </p>

            <ul>
              <li>Neutral colors and soft lights</li>

              <li>In with nature</li>

              <li>Classic bedroom</li>

              <li>Minimalist Trend</li>
            </ul>

            <div className="residential__img">
              <img
                src="https://images.unsplash.com/photo-1505693314120-0d443867891c?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGJlZHJvb218ZW58MHx8MHx8fDA%3D"
                alt="Exceptional interior design experts Dubai"
              />

              <img
                src="https://images.unsplash.com/photo-1540518614846-7eded433c457?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YmVkcm9vbXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
                alt="Professional interior design solutions Dubai"
              />
            </div>

            <li>
              <h3>Bathroom</h3>
            </li>

            <p>
              Our bathroom designs are a sanctuary within your home, offering a
              spa-like experience that blends luxury with functionality. We use
              premium materials like marble, granite, and high-end fixtures to
              create spaces that are not only elegant but also durable. Whether
              you prefer a minimalist design or something more ornate, we tailor
              the design to reflect your style, with features like rain showers,
              freestanding bathtubs, and custom vanities.
            </p>

            <div className="residential__img">
              <img
                src="https://images.unsplash.com/photo-1584622650111-993a426fbf0a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YmF0aHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                alt="Custom interior design in Dubai"
              />

              <img
                src="https://plus.unsplash.com/premium_photo-1664457233888-523931beef03?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YmF0aHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                alt="Leading modern interior design Dubai"
              />
            </div>

            <li>
              <h3>Family sitting room</h3>
            </li>

            <p>
              Our family sitting rooms are designed to be both functional and
              stylish. We create spaces where you can relax, entertain, and
              enjoy time with loved ones. With a focus on comfort and
              aesthetics, we select furnishings, fabrics, and lighting that
              create a warm and inviting atmosphere. Whether you prefer a modern
              or traditional design, we tailor the space to suit your lifestyle.
            </p>

            <div className="residential__img">
              <img
                src="https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2l0dGluZyUyMHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                alt="Premier interior decorators in Dubai"
              />

              <img
                src="https://images.unsplash.com/photo-1613685703305-8592a8a6bfee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8c2l0dGluZyUyMHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                alt="Innovative interior design services Dubai"
              />
            </div>

            <li>
              <h3>Family living room</h3>
            </li>

            <p>
              Family living rooms are the heart of your home, a space where
              memories are made. We design living rooms that are both stylish
              and inviting, with a focus on creating a space that caters to your
              family’s needs. Whether it’s a cozy space for movie nights or an
              elegant room for entertaining guests, we ensure that every
              element, from the seating to the lighting, enhances the room’s
              functionality and aesthetic appeal
            </p>

            <div className="residential__img">
              <img
                src="https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2l0dGluZyUyMHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                alt="Dubai's finest interior design professionals"
              />

              <img
                src="https://images.unsplash.com/photo-1613685703305-8592a8a6bfee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8c2l0dGluZyUyMHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                alt="Innovative interior design services Dubai"
              />
            </div>

            <li>
              <h3>Majlis interior</h3>
            </li>

            <p>
              The Majlis is a traditional space that holds great cultural
              significance, and our designs honor that tradition while
              incorporating modern luxury. We create Majlis interiors that are
              both culturally resonant and comfortable, using rich fabrics,
              intricate patterns, and elegant furnishings to create a space that
              is both welcoming and opulent. Whether you prefer a classic design
              or something more contemporary, we ensure that your Majlis
              reflects your personal style and cultural heritage.
            </p>

            <div className="residential__img">
              <img
                src="https://images.unsplash.com/photo-1666673746950-37aa01572928?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8bWFqbGlzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
                alt="Custom interior design in Dubai"
              />

              <img
                src="https://images.unsplash.com/photo-1666673746658-ba1212fbed2f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bWFqbGlzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
                alt="Leading modern interior design Dubai"
              />
            </div>

            <li>
              <h3>FOR HOSPATILITY INTERIOR:</h3>
            </li>

            <p>
              At <a href="/">Crystal Home</a>, we extend our expertise beyond
              residential spaces to include{" "}
              <a href="/services/hospitality">hospitality interior design</a>.
              We understand that the ambiance of your establishment plays a
              critical role in shaping guest experiences. Our designs are
              crafted to create inviting and luxurious environments that reflect
              the brand identity of your hospitality venue, whether it’s a
              hotel, restaurant, spa, or lounge.
            </p>

            <p>
              Our team of{" "}
              <span style={{ fontWeight: 800 }}>
                residential interior services designers in Dubai{" "}
              </span>
              specializes in creating hospitality interiors that are both
              functional and aesthetically pleasing. We balance luxury with
              practicality, ensuring that your space not only looks stunning but
              also serves the needs of your guests efficiently. From the
              selection of premium materials to the layout of communal areas,
              every detail is meticulously planned and executed to create a
              memorable experience for your guests.
            </p>

            <h3>Our Hospitality Interior Design Services Include</h3>

            <ol>
              <li style={{ marginBottom: 10, fontWeight: 800 }}>
                Reception Areas:
              </li>{" "}
              <span>
                We design welcoming and elegant reception areas that make a
                lasting first impression. From the choice of flooring to the
                lighting and furniture, we ensure that every element reflects
                the level of service your guests can expect.
              </span>
              <li style={{ marginBottom: 10, fontWeight: 800 }}>
                Dining Areas:
              </li>{" "}
              <span>
                Our dining area designs are crafted to enhance the dining
                experience. Whether it’s a formal restaurant or a casual café,
                we create spaces that are comfortable, stylish, and conducive to
                a pleasurable dining experience.
              </span>
              <li style={{ marginBottom: 10, fontWeight: 800 }}>
                Guest Rooms:
              </li>{" "}
              <span>
                We create luxurious and comfortable guest rooms that offer a
                serene retreat for your visitors. Our designs focus on
                maximizing comfort while incorporating high-end finishes and
                amenities.
              </span>
              <li style={{ marginBottom: 10, fontWeight: 800 }}>
                Lounges and Bars:
              </li>{" "}
              <span>
                Our lounge and bar designs create sophisticated and relaxing
                environments where guests can unwind. We focus on creating an
                atmosphere that is both stylish and welcoming.
              </span>
            </ol>

            <p>
              As experts in{" "}
              <span style={{ fontWeight: 800 }}>
                high-end residential interior design
              </span>
              , we understand the unique needs of Dubai’s residents and are here
              to transform your vision into reality.
            </p>

            <div className="commercial__gallery">
              {pics.map((pic, index) => {
                return (
                  <div className="pic__gallery" key={index}>
                    <div className="pic">
                      <img src={pic.url} alt={pic.alt} />
                    </div>
                  </div>
                );
              })}
            </div>
          </ol>
        </div>
      </div>
    </>
  );
}

export default Residential;
