import React from "react";
import ReactTypingEffect from "react-typing-effect";
import Heading from "../../../components/text/Heading";
import "./Restaurant.css";
import Head from "../../../components/meta/Head";

function Restaurant() {
  const pics = [
    {
      url: "https://images.unsplash.com/photo-1537047902294-62a40c20a6ae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8cmVzdGF1cmFudHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
      alt: "restaurant interior design",
    },

    {
      url: "https://images.unsplash.com/photo-1578474846511-04ba529f0b88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fHJlc3RhdXJhbnR8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

      alt: "cafe interior design",
    },

    {
      url: "https://images.unsplash.com/photo-1552566626-52f8b828add9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8cmVzdGF1cmFudHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
      alt: "restaurant interior",
    },

    {
      url: "https://images.unsplash.com/photo-1590846406792-0adc7f938f1d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8cmVzdGF1cmFudHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

      alt: "interior design office near me",
    },

    {
      url: "https://images.unsplash.com/photo-1514933651103-005eec06c04b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fHJlc3RhdXJhbnR8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
      alt: "top interior design companies in dubai",
    },
  ];
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={["Restaurant Interior Design Dubai"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={"Restaurant Interior Design | Enhance Your Dining Experience"}
        desc={
          "Discover premium restaurant interior design with Crystal Home. From cafes to fine dining, we craft bespoke interiors that elevate your brand"
        }
        link={
          "https://www.crystalhomeinterior.com/our-services/restaurant-interior-design"
        }
      />
      <div className="restaurant__banner">
        <div className="restaurant__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="restaurant">
        <div className="restaurant__content">
          <Heading text2={"Restaurant Interior Design Service"} />

          <p>
            At<a href="/"> Crystal Home,</a> our{" "}
            <span style={{ fontWeight: 800 }}> restaurant interior design</span>{" "}
            services focus on creating environments that offer warmth, comfort,
            and a welcoming ambiance. We ensure that every detail, from lighting
            and temperature to furnishings and layout, is meticulously planned
            to enhance the overall dining experience. Our expert team works
            closely with you to ensure that your vision, budget, and goals are
            fully realized. Whether you're aiming for a cozy cafe or a high-end
            dining experience, we craft interiors that not only stand out but
            also resonate with your brand identity, ensuring your restaurant
            becomes a destination in Dubai.
          </p>

          <ul>
            <li>
              <h3> Fine Dining Restaurants</h3>
            </li>

            <div className="restaurang__img">
              <img
                src="https://images.unsplash.com/photo-1559329007-40df8a9345d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8ZmluZSUyMHJlc3RhdXJhbnR8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                alt="High-end office interior designers in Dubai"
              />
            </div>

            <p>
              In the world of restaurant interior design, fine dining demands
              elegance and sophistication. We provide creative design strategies
              and full infrastructure support, helping you outperform local
              competitors. Our interiors for fine dining restaurants are crafted
              to create an exclusive atmosphere, where every element— lighting,
              seating, and décor—contributes to an unforgettable dining
              experience. Let Crystal Home help you create a space that not only
              attracts but retains a discerning clientele
            </p>

            <li>
              <h3>Family/Casual Dining Restaurant</h3>
            </li>

            <div className="restaurang__img">
              <img
                src="https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cmVzdGF1cmFudHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
                alt="Cutting-edge interior design studios Dubai"
              />
            </div>

            <p>
              For <span className="bold__span">family and casual dining</span>
              restaurants, we focus on creating spaces that are inviting,
              comfortable, and versatile. Our designs blend style with
              practicality, ensuring that the environment is both functional and
              aesthetically pleasing. We provide full-service interior design
              solutions that cater to the needs of diverse clientele, offering
              layouts and furnishings that encourage relaxation and enjoyment,
              making every meal a memorable one
            </p>

            <li>
              <h3>Cafe Interior Design</h3>
            </li>

            <div className="restaurang__img">
              <img
                src="https://images.unsplash.com/photo-1554118811-1e0d58224f24?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y2FmZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
                alt="Elegant interior design firms Dubai"
              />
            </div>

            <p>
              Cafes have become the social hubs of modern society, especially
              for millennials. Our{" "}
              <span className="bold__span">cafe interior design</span> services
              are centered on creating trendy, comfortable spaces that reflect a
              specific theme or vibe. From quirky artwork to modern furniture,
              we pay attention to every detail to ensure that your cafe stands
              out in a competitive market. The right blend of music, lighting,
              and décor will create an atmosphere that keeps customers coming
              back.
            </p>

            <li>
              <h3>Bars & Night Clubs</h3>
            </li>

            <div className="restaurang__img">
              <img
                src="https://images.unsplash.com/photo-1578736641330-3155e606cd40?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2x1YnN8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                alt="Dubai's most sought-after interior designers"
              />
            </div>

            <p>
              Designing interiors for{" "}
              <span className="bold__span">bars and nightclubs</span> requires a
              focus on entertainment and comfort. At{" "}
              <a href="/">Crystal Home</a>, we design spaces where the entire
              interior revolves around the bar and dance floor. Our goal is to
              create an atmosphere that is not only entertaining and spacious
              but also comfortable. From furniture selection to wall art, we
              provide comprehensive design services that cater to the unique
              needs of nightlife venues, ensuring your bar or club stands out in
              Dubai's vibrant scene.
            </p>

            <li>
              <h3>Bakeries</h3>
            </li>

            <div className="restaurant__sides">
              <div className="restaurang__img">
                <img
                  src="https://images.unsplash.com/photo-1587241321921-91a834d6d191?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8YmFrZXJpZXN8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                  alt="Artistic interior designers Dubai"
                />
              </div>

              <div>
                <p>
                  Looking for expert{" "}
                  <span className="bold__span">bakery interior design </span>
                  services? At <a href="/">Crystal Home</a>, we bring your ideas
                  to life with specialized and efficient design solutions. Our
                  team of designers is dedicated to creating bakery interiors
                  that are not only functional but also visually appealing,
                  ensuring that your bakery becomes a must-visit spot for
                  customers. We collaborate with you from the initial concept
                  design through to the final touches, ensuring every aspect
                  aligns with your brand and operational needs.
                </p>

                <p>
                  Crystal Homes uses brand thinking to create restaurant
                  interiors that are highly connected to your brand DNA. Crystal
                  Homes is a design studio that is made up of restaurant brand
                  designers and restaurant interior designers. We work
                  collectively to ensure that your restaurant branding aligns
                  with your restaurant interior design.
                </p>

                <p>
                  We can support your restaurant interior design project from
                  the initial concept design right through to visualisation. We
                  are able to support you through the technical stages of the
                  project with the assistance of technical drawing packs and the
                  coordination of contractors who will support you with the fit
                  out.
                </p>

                <p>
                  Here are some of the most frequently asked questions when it
                  comes to restaurant interior design.
                </p>
              </div>
            </div>

            <Heading text={"How We Decor Restaurants"} />

            <li>
              <h3>First Impressions</h3>
            </li>

            <p>
              {" "}
              First impressions are crucial in the restaurant experience,
              leaving a lasting impact on customers long after they leave. Your
              restaurant's <span className="bold__span">
                interior design
              </span>{" "}
              should clearly convey your brand's essence and purpose. When
              executed effectively, a well-thought-out design fosters trust and
              confidence, encouraging customers to choose your establishment for
              their dining experience.
            </p>

            <li>
              <h3>Your Brand Story</h3>
            </li>

            <p>
              While it may seem trivial, telling your brand story is essential
              in creating not just a restaurant, but a beloved brand that
              customers want to return to. The most successful hospitality
              brands are built around their core values and purpose,
              encapsulating their essence and engaging customers emotionally. A
              compelling brand story is a key element in{" "}
              <span className="bold__span">cafe interior design</span>
              that resonates with patrons.
            </p>

            <li>
              <h3>Interior Concept</h3>
            </li>

            <p>
              Creating a clear vision for your restaurant's space is vital. Your
              <span className="bold__span"> interior concept</span> should align
              with your overall brand, shaping the mood and atmosphere you wish
              to convey. Research and planning should focus on staying true to
              your brand while keeping the customer experience at the forefront.
              This alignment is crucial in establishing a memorable dining
              environment.
            </p>

            <li>
              <h3>Spatial planning</h3>
            </li>

            <p>
              Effective <span className="bold__span">restaurant interior</span>{" "}
              design includes meticulous spatial planning, which streamlines the
              operation and functionality of the space. A well-developed floor
              plan is essential for ensuring the restaurant operates smoothly,
              considering factors like the entrance, waiting stations, dining
              zones, bar, kitchen, and restrooms. A thoughtful layout enhances
              customer flow and overall satisfaction.
            </p>

            <li>
              <h3>Furniture, Fixtures & Equipment</h3>
            </li>

            <p>
              Furniture, fixtures, and equipment (FF&E) play a pivotal role in
              shaping the look, feel, and functionality of your restaurant's
              interior. Consistency in furniture style and color reinforces your
              brand identity while maximizing the available space. Depending on
              the size of your establishment, consider a mix of seating options
              to create a welcoming atmosphere that caters to diverse customer
              preferences. By focusing on these key elements, we ensure that
              your restaurant's interior design not only reflects your brand but
              also provides an exceptional dining experience for your guests.
            </p>

            <div className="commercial__gallery">
              {pics.map((pic, index) => {
                return (
                  <div className="pic__gallery" key={index}>
                    <div className="pic">
                      <img src={pic.url} alt={pic.alt} />
                    </div>
                  </div>
                );
              })}
            </div>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Restaurant;
