import React, { useEffect, useState } from "react";
import Banner from "../../components/banner/Banner";
import "../single/Single.css";
import { Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import Head from "../../components/meta/Head";

function WoodWorkInterior() {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width <= 550) {
      setMobile(true);
    }
  }, [mobile]);
  const excerpts = [
    "https://images.unsplash.com/photo-1497219055242-93359eeed651?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8d29vZHdvcmt8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1426927308491-6380b6a9936f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8d29vZHdvcmt8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1590529989936-f6efdf774c23?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8d29vZHdvcmt8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1463082459669-fd1ca1692fea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8d29vZHdvcmt8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1520554704788-a6a2e7854290?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fHdvb2R3b3JrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1502343019212-cc6a09783255?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHdvb2R3b3JrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
  ];
  return (
    <>
      <Head
        link={
          "https://www.crystalhomeinterior.com/our-projects/woodwork-interior-designs"
        }
      />
      <Banner
        img={
          "https://images.unsplash.com/photo-1502343019212-cc6a09783255?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHdvb2R3b3JrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
        }
      />
      <div className="single">
        <div className="excerpts">
          <Swiper
            spaceBetween={4}
            slidesPerView={mobile ? 1 : 2}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            pagination={{ clickable: false }}
            modules={[Autoplay]}
            className="single__swiper"
          >
            {excerpts.map((client, index) => {
              return (
                <div key={index} className="excerpts__content">
                  <SwiperSlide>
                    <img src={client} alt="" />
                  </SwiperSlide>
                </div>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default WoodWorkInterior;
