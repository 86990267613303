import React from "react";
import ReactTypingEffect from "react-typing-effect";
import Heading from "../../../components/text/Heading";
import "./Retail.css";
import Head from "../../../components/meta/Head";

function Retail() {
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={["retail fit out"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={"Retail Fit Out Services in Dubai | Crystal Home Interiors"}
        desc="Enhance your space with retail expert fit-out services. We create unique brand identities through top designs and efficient space planning"
        link={"https://www.crystalhomeinterior.com/our-services/retail-fit-out"}
      />
      <div className="retail__banner">
        <div className="retail__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="retail">
        <Heading text2={"Retail Interior Designs Dubai"} />

        <div className="retail__content">
          <p>
            Your retail outlet is the face of your business, and its design
            plays a pivotal role in attracting customers and creating a lasting
            impression. An effective retail fit out not only enhances the
            aesthetic appeal of your store but also helps in establishing a
            unique brand identity that resonates with your target audience. At
            Falcon Interior, we leverage modern technologies and a skilled
            workforce to deliver exceptional retail fit out solutions tailored
            to meet the specific needs of all types of retail businesses
          </p>

          <Heading text={" Creating a Lasting Impression"} />

          <p>
            The retail environment is a crucial touchpoint for your customers.
            It’s essential to establish and maintain a positive impression on
            every visitor, as this can significantly impact their shopping
            experience and brand perception. In a competitive market like the
            UAE, finding a trustworthy and reputable fit out contractor can feel
            overwhelming. That’s where Falcon Interior comes in. As one of the
            foremost{" "}
            <span className="bold__span">
              Retail Fit Out Companies in Dubai
            </span>
            , we pride ourselves on offering comprehensive and effective
            solutions for every retail store in the city.
          </p>

          <Heading text={"Comprehensive Retail Fit Out Services"} />

          <p>
            Our extensive range of <a href="/our-services">services</a> ensures
            that every aspect of your retail fit out is meticulously planned and
            executed. Some key components of our fit out services include:
          </p>

          <ul>
            <div className="list__spacing">
              <li className="bold__list"> Planning of Installation Designs:</li>

              <span>
                We develop tailored installation plans that maximize the
                functionality of your space while aligning with your brand
                identity.
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list">
                {" "}
                Equipment Positioning and Arrangement:
              </li>

              <span>
                Our team ensures optimal placement of equipment to enhance
                operational efficiency and customer flow.
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list">
                {" "}
                Floor Designing with Material Selections:{" "}
              </li>

              <span>
                We select durable and visually appealing materials that
                complement your retail concept, ensuring a cohesive look.
              </span>
            </div>
            <div className="list__spacing">
              <li className="bold__list"> Tile and Structure Layout: </li>

              <span>
                Attention to detail in layout design enhances both aesthetics
                and functionality, improving the overall shopping experience
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list">
                {" "}
                Aeration and Air Conditioning Systems:{" "}
              </li>

              <span>
                We design and implement effective climate control systems to
                keep your store comfortable for customers and staff alike.
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list">
                {" "}
                Safety and Ventilation System Design:{" "}
              </li>

              <span>
                Safety is paramount in retail environments. Our designs
                prioritize safety protocols while ensuring adequate ventilation
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list">
                {" "}
                Light, Views, and Device Selections:{" "}
              </li>

              <span>
                Thoughtful lighting enhances product visibility and creates an
                inviting atmosphere, while device selections optimize customer
                engagement.
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list"> Zone Planning and Spacing: </li>

              <span>
                Effective zoning helps in organizing your retail space, making
                navigation easier for customers.
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list"> E-Devices Management and Setup: </li>

              <span>
                We integrate technology seamlessly into your retail environment
                to improve operational efficiency and customer interaction.
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list">Décor and Lighting Management: </li>

              <span>
                From decorative elements to lighting fixtures, we ensure every
                detail contributes to the overall ambiance of your store.
              </span>
            </div>
          </ul>

          <Heading text={" Your Partner in Retail Success"} />

          <p>
            At Falcon Interior, our mission is to transform your retail space
            into an engaging and memorable environment that encourages customers
            to return. With our expertise in retail fit out, we are committed to
            delivering innovative designs that not only enhance the visual
            appeal of your outlet but also drive profitability. Let us help you
            create a retail experience that stands out in the bustling market of
            Dubai!
          </p>

          {/* 
          <ul>
            <li>Equipment positioning and arrangement</li>

            <li>Floor designing with material selections</li>

            <li>Tile and structure layout</li>

            <li>Aeration and Air conditioning system</li>

            <li>Safety and ventilation system design</li>

            <li>Light, views and device selections</li>

            <li>Zone planning and spacing</li>

            <li>E- devices management and setup</li>

            <li>Décor and light, Cornices and sets management</li>
          </ul> */}
        </div>
      </div>
    </>
  );
}

export default Retail;
