import "./Home.css";
import "../projects/Projects.css";
import React, { useEffect, useState } from "react";
import Button from "../../components/button/Button";
import Clients from "../../components/clients/Clients";
import Offer from "../../components/offers/Offer";
import Heading from "../../components/text/Heading";
import Text from "../../components/text/Text";
import file from "../../assets/file/catalog.pdf";

import {
  ApartmentIcon,
  ArrowForwardIcon,
  PeopleIcon,
  SchoolIcon,
  StarHalfIcon,
  StarIcon,
} from "../../imports/Icons";
import { Pagination, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Client1,
  Client10,
  Client2,
  Client3,
  Client4,
  Client5,
  Client6,
  Client7,
  Client8,
  Client9,
  Google,
  HomeVideo,
  Logo,
  Offer1,
  Offer2,
  Offer3,
  Offer4,
  Offer5,
  Offer6,
  Powered,
} from "../../imports/Images";
import Review from "../../components/review/Review";
import Data from "../projects/data";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Head from "../../components/meta/Head";

// Offers data
const offers = [
  {
    img: Offer1,
    title: "residential",
    link: "/services/residential",
    desc: "Creating personalized and luxurious living spaces in Dubai.",
    alt: "residential interior design dubai",
  },
  {
    img: Offer2,
    title: "commercial",
    link: "/services/commercial",
    desc: "Designing efficient and stylish business environments.",
    alt: "commercial fit out solutions in dubai",
  },
  {
    img: Offer3,
    title: "hospitality",
    link: "/services/hospitality",
    desc: "Crafting inviting interiors for hotels and restaurants.",
    alt: "hospitality interior design dubai",
  },
  {
    img: Offer4,
    title: "beauty salon",
    link: "/services/beauty",
    desc: "Transforming salons into premium retreats.",
    alt: "salon interior design dubai",
  },
  {
    img: Offer5,
    title: "retail fit out",
    link: "/services/retail",
    desc: "Enhancing customer experience with strategic interior design.",
    alt: "fit out dubai company",
  },
  {
    img: Offer6,
    title: "medical",
    link: "/services/medical",
    desc: "Designing patient-centric healthcare interiors.",
    alt: "hospital interior solutions dubai",
  },
];

// Reviews data
const reviews = [
  {
    name: "bilal awan",
    date: "2 days ago",
    desc: "Crystal Home has an amazing gift to transform your home from ordinary to outstanding! From design to execution they makes it fun and even exhilarating. They works to create a plan of action with different options and varying budgets. I highly recommend Crystal Home for your interior design project.",
  },
  {
    name: "kashif sultan",
    date: "a month ago",
    desc: "I loved working with Crystal home and there team and would highly recommend anyone looking for an interior designer who brings creativity but designs something that fits YOU and your style.",
  },
  {
    name: "harrison",
    date: "3 months ago",
    desc: "Crystal home team is extremely knowledgeable and professional. Made me feel super comfortable even though it was my first experience working with a designer. Highly recommend.",
  },
  {
    name: "john doe",
    date: "3 months ago",
    desc: "Crystal Home has a great sense of what designs are both in style, but will stand the test of time... We can't thank her enough.",
  },
];

const clients = [
  Client1,
  Client2,
  Client3,
  Client4,
  Client5,
  Client6,
  Client7,
  Client8,
  Client9,
  Client10,
];

function Home() {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width <= 550) {
      setMobile(true);
    }
  }, [mobile]);

  return (
    <div className="home">
      <Head link={"https://www.crystalhomeinterior.com/"} />
      <div className="home__video">
        <video muted autoPlay loop src={HomeVideo}></video>
        <div className="home__video__text">
          <h1>Your Trusted Interior Designing Company in Dubai</h1>
          <p>Transforming Interiors Across Dubai</p>
          <a href="/services">
            <Button text={"Discover Our Work"} color={"white"} />
          </a>
        </div>
      </div>

      <div className="home__hero">
        <div className="home__hero__contents">
          <div className="home__hero__contents__logo">
            <img src={Logo} alt="Crystal Home Logo" />
          </div>
          <div className="home__hero__contents__text">
            {/* <Text
              text={"Crystal Home Interior Decoration Company Dubai"}
              color={"#CD9A61"}
            /> */}

            <h2>Crystal Home - Leading Interior Decoration Company Dubai</h2>
            <h3>Expert Fit Out and Best Interior Design In Dubai</h3>
            <p style={{ color: "#000" }}>
              Crystal Home stands out as a premier interior designer in the
              Dubai UAE, known for crafting luxury interiors for a range of
              sophisticated spaces. Our expertise spans modern villas,
              residential areas, highend restaurants, premium beauty salons,
              expansive office fit-outs, and both{" "}
              <a
                href="/our-services/commercial-design-interior"
                style={{ color: "black", textDecoration: "underline" }}
              >
                commercial
              </a>{" "}
              and{" "}
              <a
                href="/our-services/best-hospitality-design-firms-dubai"
                style={{ color: "black", textDecoration: "underline" }}
              >
                hospitality interiors
              </a>{" "}
            </p>
            <p style={{ color: "#000" }}>
              Using the latest technologies and the finest construction
              materials, we meticulously plan and execute designs that cater to
              your specific needs and tastes. Whether you aim to build your
              ideal home or refresh a commercial property, our skilled team is
              dedicated to turning your vision into reality. As leading{" "}
              <a
                href="/our-services/interior-design-services-company"
                style={{ color: "black", textDecoration: "underline" }}
              >
                {" "}
                interior designers
              </a>{" "}
              in the UAE, we blend contemporary trends with your unique style to
              create spaces that are not just elegant and exquisite but also
              functional and modular.
            </p>
          </div>
        </div>

        <div className="home__offers">
          <Heading
            text={"What We Offer?"}
            // others={"Explore Our Interior Design Services in Dubai"}
          />

          <p style={{ textAlign: "center" }}>
            Explore Our Interior Design Services in Dubai
          </p>
          <div className="home__offers__container">
            {offers.map((offer, index) => (
              <div key={index} className="offer__content">
                <Offer
                  img={offer.img}
                  title={offer.title}
                  link={offer.link}
                  desc={offer.desc}
                  alt={offer.alt}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="projects home">
          <Heading text={"Our Latest Projects Of Interior Designs Dubai"} />
          <div className="home__projects__container home">
            {Data.slice(0, 6).map((project, index) => (
              <div key={index} className="projects page">
                <div className="projects__contents home">
                  <img src={project.img} alt={project.title} />
                  <div className="projects__hover__contents">
                    <div>
                      <p id="beauty">beauty salon & spa</p>
                      <h3>{project.title}</h3>
                      <a href={project.url}>
                        <p>Learn more</p>
                        <span>
                          <ArrowForwardIcon />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <h3 className="main__text">{project.bottomText}</h3>
              </div>
            ))}
          </div>
          <a href="/projects">
            <Button text={"more projects"} color={"var(--gold)"} />
          </a>
        </div>
      </div>

      <div className="home__logos">
        <Heading text={"Serving Dubai's Leading Brands with Excellence"} />
        <div className="home__logos__main">
          <Swiper
            spaceBetween={4}
            slidesPerView={mobile ? 2 : 4}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            pagination={{ clickable: false }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {clients.map((client, index) => (
              <SwiperSlide key={index}>
                <img src={client} alt={`Client ${index + 1}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="home__reviews">
        <div className="home__reviews__rate">
          <div className="home__reviews__rate__img">
            <img src={Logo} alt="Crystal Home Logo" />
          </div>
          <div className="home__reviews__rate__details">
            <h3>crystal home</h3>
            <div className="rate">
              <p>4.7</p>
              <p>
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarHalfIcon />
              </p>
            </div>
            <div className="desc">
              <p>Based on 90 reviews</p>
              <img src={Powered} alt="Powered by Google" />
            </div>
            <div className="review__us">
              <a
                href="https://g.co/kgs/ekbwX2"
                target="_blank"
                rel="noreferrer"
              >
                review us on
              </a>
              <img src={Google} alt="Google Reviews" />
            </div>
          </div>
        </div>

        <Swiper
          spaceBetween={50}
          slidesPerView={mobile ? 1 : 2}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          pagination={{ clickable: false }}
          modules={[Pagination, Autoplay]}
          className="home__reviews__slider"
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <Review
                name={review.name}
                date={review.date}
                desc={review.desc}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="home__clients">
        <p>
          <Clients name={"projects"} icon={<ApartmentIcon />} number={300} />
        </p>

        <p>
          <Clients name={"happy clients"} icon={<PeopleIcon />} number={250} />
        </p>

        <p>
          <Clients
            name={"qualified staffs"}
            icon={<SchoolIcon />}
            number={132}
          />
        </p>
      </div>

      <div className="home__portfolio">
        <div className="home__portfolio__file">
          <a href={file} style={{ fontWeight: "bold" }} download>
            <Button text={"download our portfolio"} color={"var(--gold)"} />
          </a>
        </div>

        <div className="home__portfolio__text">
          <h3>
            About Crystal Home - Your Premier Dubai Interior Designer Partner
          </h3>
          <p>
            Crystal Home, a top interior design firm in Dubai, offers tailored
            solutions for residential, commercial, and hospitality spaces. Our
            expertise lies in crafting unique environments that are both
            beautiful and functional, using the latest design trends and quality
            materials....
            <a
              href="/about"
              style={{ color: "#000", textDecoration: "underline" }}
            >
              read more
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
