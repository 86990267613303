import React from "react";
import Heading from "../../components/text/Heading";
import {
  Service1,
  Service2,
  Service3,
  Service4,
  Service5,
  Service6,
} from "../../imports/Images";
import Offer from "../../components/offers/Offer";
import "./Services.css";

import ReactTypingEffect from "react-typing-effect";
import Head from "../../components/meta/Head";

const services = [
  {
    img: Service1,
    link: "/services/commercial",
    desc: "Transform your workspace into a functional and aesthetically pleasing environment with our commercial interior design services.",
    title: "commercial",
    alt: "commercial fit out solutions in dubai ",
  },
  {
    img: Service2,
    link: "/services/residential",
    title: "residential",
    desc: "Elevate your living spaces with modern, luxurious designs tailored to your style and preferences",
    alt: "residential interior design dubai ",
  },
  {
    img: Service3,
    link: "/services/hospitality",
    title: "hospitality",
    desc: "Create inviting and sophisticated hospitality spaces that impress and comfort your guests.",
    alt: "hospitality interior design dubai",
  },
  {
    img: Service4,
    link: "/services/beauty",
    title: "beauty salon & spa",
    desc: "Design a serene and stylish atmosphere for your beauty salon or spa, reflecting the latest trends and comfort.",
    alt: "salon interior design dubai",
  },
  {
    img: Service5,
    link: "/services/office",
    title: "office fit out",
    desc: "Optimize your office space for productivity and comfort with our expert fit-out services",
    alt: "office interior solutions dubai",
  },
  {
    img: Service6,
    link: "/services/retail",
    title: "retail shop",
    desc: "Enhance your retail space to attract customers and elevate the shopping experience.",
    alt: "retail shop interior designs expert dubai",
  },
];

const ReactTypingEffectDemo = () => {
  return (
    <>
      {/* <ReactTypingEffect text={[".", "!"]} /> */}

      <br />

      <ReactTypingEffect
        text={["Our Services"]}
        cursorRenderer={(cursor) => <h5>{cursor}</h5>}
        displayTextRenderer={(text, i) => {
          return (
            <h1>
              {text.split("").map((char, i) => {
                const key = `${i}`;
                return (
                  <span key={key} style={i % 2 === 0 ? { color: "white" } : {}}>
                    {char}
                  </span>
                );
              })}
            </h1>
          );
        }}
      />
    </>
  );
};

function Services() {
  return (
    <>
      <Head
        title={"Our Services | Crystal Home Interior Design in Dubai"}
        desc={
          "Explore Crystal Home Interior's expert design services in Dubai, specializing in commercial, residential, hospitality, retail, and beauty salon spaces."
        }
        link={"https://www.crystalhomeinterior.com/our-services"}
      />
      <div className="services__banner">
        <div className="services__header">
          {" "}
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="services">
        <p className="subtext">
          At<a href="/"> Crystal Home</a> Interior, we strive to exceed client
          expectations by delivering competitive pricing, innovative designs,
          and top-tier services across various projects. Explore our wide range
          of services designed to cater to your unique needs in commercial,
          residential, hospitality, retail, and beauty salon spaces.
        </p>
        <p className="heading">
          <Heading text={"our services"} />
        </p>

        {/* <div className="services__contents">
          <Offer
            img={Service1}
            link="/services/commercial"
            desc={
              "Transform your workspace into a functional and aesthetically pleasing environment with our commercial interior design services."
            }
            title={"commercial"}
          />
          <Offer
            img={Service2}
            link="/services/residential"
            title={"residential"}
          />
          <Offer
            img={Service3}
            link="/services/hospitality"
            title={"hospitality"}
          />
          <Offer
            img={Service4}
            link="/services/beauty"
            title={"beauty salon & spa"}
          />
          <Offer
            img={Service5}
            link="/services/office"
            title={"office fit out"}
          />
          <Offer img={Service6} link="/services/retail" title={"retail shop"} />
        </div> */}

        <div className="home__offers__container">
          {services.map((service, index) => (
            <div key={index} className="offer__content">
              <Offer
                key={index}
                img={service.img}
                link={service.link}
                desc={service.desc}
                title={service.title}
                alt={service.alt}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Services;
